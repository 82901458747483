<template>
  <div class="wrap">
    <div class="title">
      <FunTamLogoIcon v-if="!settings.labelSettings.shareLogo"/>
      <img v-else :src="settings.labelSettings.shareLogo" alt="" />
      <div class="text">{{$t('share:title')}}</div>
    </div>
    <div class="stores">
      <div class="col">
        <LinkToAppStore :href="settings.labelSettings.appStoreLink" class="store-link" badge="appStore" :lang="lang" />
        <q-r-i-link-icon v-if="!isMobile" class="qrcode" :link="settings.labelSettings.appStoreLink"/>
      </div>
      <div class="col">
        <LinkToAppStore :href="settings.labelSettings.googlePlayLink" class="store-link" badge="googlePlay" :lang="lang" />
        <q-r-i-link-icon v-if="!isMobile" class="qrcode" :link="settings.labelSettings.googlePlayLink"/>
      </div>
      <LinkToAppStore v-if="false" class="store-link" badge="ruStore"/>
    </div>
    <router-link class="continue" :to="{name: 'offer_mapPoi', query: {showMainEvent: 'false', shareId: id, shareType: type}}">
      <span class="v-btn__content">
        <span data-v-209b2c50="" class="button-label">{{$t('share:continue')}}</span>
        <svg data-v-209b2c50="" width=".5em" height="1em" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg"><path data-v-209b2c50="" opacity="0.7" d="M1 10L4.70455 6.29545C5.14205 5.85795 5.14205 5.14205 4.70455 4.70455L1 1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
      </span>
    </router-link>
  </div>
</template>

<script>
import LinkToAppStore from '@/component/element/link-to-app-store'
import FunTamLogoIcon from '@/component/icons/FunTamLogoIcon'
import {isMobile} from 'mobile-device-detect';
import QRILinkIcon from "@/component/icons/QRILinkIcon.vue";

export default {
  name: "Share",
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    type: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      developing: true,
      isMobile,
      lang: Language.getName(),
      settings: {
        labelSettings: CONST.labelSettings[Language.getName()],
      },
    }
  },
  created() {
    Language.set(this.$route.query.lang || 'ru')
  },
  components: {
    QRILinkIcon,
    LinkToAppStore,
    FunTamLogoIcon,
  },
}
</script>

<style scoped lang="scss">
  .wrap {
    width: 700px;
    max-width: 100%;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    align-items: center;
    padding: 20px;
    min-height: 100%;
    flex-flow: wrap;
    justify-content: center;

    .title {
      font-size: 2em;
      text-align: center;
      display: flex;
      flex-flow: column;
      width: 100%;

      .text {
        color: #898989;
        margin-top: 1em;

        @media (max-width: 700px) {
          font-size: 4vw;
        }

        @media (max-width: 400px) {
          font-size: 5vw;
          white-space: break-spaces;
        }
      }
    }

    .stores {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 10px 0;
      margin: 20px 0 0 0;
      position: relative;

      @media (max-width: 500px) {
        flex-flow: column;
      }

      .col {
        display: flex;
        flex-flow: column;
        align-items: center;
      }

      .store-link ::v-deep .img {
        height: 50px;
      }

      .qrcode {
        padding: 20px;
      }

      //.store-link:not(:first-child) {
      //  margin-left: 20px;
      //}
    }

    .continue {
      font-size: 1.5em;
      margin: 20px 0 0 0;
      display: flex;
      background-color: #F5366F;
      color: #ffffff;
      border: 1px solid #F5366F;
      font-weight: 700;
      font-family: Montserrat,sans-serif;
      border-radius: 60px;
      align-items: center;
      padding: .5em 1.5em;

      @media (max-width: 350px) {
        font-size: 6vw;
      }

      &:hover {
        background-color: #ffffff;
        color: #F5366F;
        border: 1px solid #F5366F;

        .svg {
          stroke: #F5366F;
        }
      }

      .svg {
        margin-left: 5px;
        stroke: #ffffff;
      }
    }
  }
</style>