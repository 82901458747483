<template>
  <qrcode-vue :value="link" :size="size" render-as="svg" :level="level" :background="background" :foreground="foreground" />
</template>

<script>
import qrcodeVue from 'qrcode.vue'
export default {
  name: "QRILinkIcon",
  props: {
    link: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 230
    },
    level: {
      type: String,
      default: 'L'
    },
    background: {
      type: String,
      default: '#ffffff'
    },
    foreground: {
      type: String,
      default: '#000000'
    }
  },
  components: {
    qrcodeVue
  }
}
</script>

<style scoped>

</style>