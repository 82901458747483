<template>
  <a v-if="href" :href="href" class="img-link"><img :src="apps[badge][lang]" alt="" class="img"></a>
</template>

<script>
export default {
  name: "link-to-app-store",
  props: {
    href: {
      type: [String, Boolean],
      default: false
    },
    badge: {
      type: String,
      required: true
    },
    lang: {
      type: String,
      default: 'en'
    },
  },
  data() {
    return {
      apps: {
        googlePlay: {
          en: require('@img/stores/google-play-badge-en.png'),
          ru: require('@img/stores/google-play-badge-ru.png')
        },
        appStore: {
          en: require('@img/stores/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'),
          ru: require('@img/stores/Download_on_the_App_Store_Badge_RU_RGB_blk_100317.svg')
        },
        ruStore: {
          en: require('@img/stores/ru-store-badge.png'),
          ru: require('@img/stores/ru-store-badge.png')
        },
      }
    }
  },
}
</script>

<style scoped>

</style>